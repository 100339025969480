// 无极远程搜索 + 回显
<template>
  <el-select ref="remoteSelect" filterable v-model="selModel" v-loading="loading" v-select-load="downLoad"
    v-bind="$attrs" no-data-text="无可选项" :placeholder="option.placeholder || $attrs.placeholder" :popper-class="`load-remote my-select-dropdown local-${option.type || ''} remote-${
      multiple ? 'multiple' : 'radio'
    }`" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255,1)" :remote="visible" :loading="remoteLoad"
    :value-key="option.valueKey" :collapse-tags="option.collapseTags || false" :remote-method="debounceMethod"
    :reserve-keyword="option.reserveKeyword || true" :multiple-limit="option.multipleLimit || 0"
    :clearable="option.clearable || false" :class="['sys-remote', loading ? 'remote-hide' : '']"
    :disabled="loading || option.disabled || $attrs.disabled" @change="change" @visible-change="visibleChange"
    :sunyunSelect="sunyunSelect" :size="size" @keydown.native="handleSelectKeydown($event)">
    <template v-if="option.labels">
      <div class="static"  :class="{sticky:isKeying}">
        <span class="headTitle" :style="`${i !== 0 ? 'color: #8492a6;font-size: 13px;' : ''}min-width: ${
            item.width || '150'
          }px;width: ${item.width || '150'}px;max-width: ${item.width || '150'}px;`" v-for="(item, i) in option.labels"
          :key="item.title + i">
          {{ item.title }}
        </span>
      </div>
    </template>
    <el-option v-if="showNoData" label="无可选项" value="v@404@^" :style="showStyle" disabled />
    <template v-else-if="!remoteLoad">
      <el-checkbox-group v-if="multiple" v-model="selModel">
        <el-option v-for="(obj, i) in data" :key="`${obj[keyName]}.${i}`" :label="obj[option.label]"
          :disabled="obj.disabled" :value="isValuekey ? obj : obj[keyName]">
          <div :class="['label']">
            <el-checkbox style="pointer-events: none" :label="isValuekey ? obj : obj[keyName]">{{
            }}</el-checkbox>
            <template v-if="option.labels">
              <span class="headTitle" v-for="(item, index) in option.labels" :key="item.label + index">
                {{ obj[item.label] }}
              </span>
            </template>
            <span v-else class="headTitle">
              {{ obj[option.label] }}
            </span>
          </div>
        </el-option>
      </el-checkbox-group>
      <template v-else>
        <el-option v-for="(obj, i) in data" :key="`${obj[keyName]}.${i}`" :label="obj[option.label]"
          :disabled="obj.disabled" :value="isValuekey ? obj : obj[keyName]">
          <div :class="[
              data.findIndex((x) => x[keyName] === obj[keyName]) === i
                ? 'label'
                : 'label2',
            ]" v-if="option.labels">
            <span class="headTitle" v-for="(item, index) in option.labels" :key="`${item.label}.${index}.${i}`">
              {{ obj[item.label] }}
            </span>
          </div>
        </el-option>
      </template>
    </template>
    <el-option v-if="downLoading" disabled label="加载中..." value="v@loading@^" :style="loadStyle" />
    <div class="static static-bottom" v-if="buttons" :class="{sticky:isKeying}">
      <div class="content" v-for="(item, i) in buttons" :key="`${item.title}${i}`">
        <el-link :icon="item.icon" @click="handleEvent(item)">
          {{ item.title }}
        </el-link>
      </div>
    </div>
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent({ click: 'getSelectData' })" />
  </el-select>
</template>
<script>
import { deepCopy, directives, strEscape, haveContent, isArray } from "@/utils";
import { mixin } from "@/components/tablePage/select/mixin/mixin-select.js";
/**
 * option 配置（还支持 全部element select 组件支持的属性）
 * 1   showItem    [Array]                         手动回显数据 数据更新时 先 model 赋值 再 showItem 赋值 接口不会监听 model 数据变化
 * 2   remote      [async Function]                请求列表数据接口
 * 2.1 remoteFilter[String]                        模糊搜索字段、默认 query
 * 2.2 remoteEcho  [String]                        数据回显搜索字段默认值回显搜索字段、默认 option.valueKey | option.value 多选时默认值后面加s
 * 2.3 remoteBody  [Function Object]               请求列表的必要条件或方法 修改请求前数据 默认 {}
 * 2.4 remoteKey   [Function String]               请求列表的返回数据的参数名或方法 默认 'rows'
 * 3   downLoad    [Number]                        分页每页显示数据（默认值 15 ）
 * 4   valueKey    [String]                        Object Key 值（v-model 为 Object ）
 * 5   value       [String]                        Object Key 值（v-model 为该值）
 * 6   labels      [Array]                         表格页面展示多字段
 * 6.1 { title: "", label: "" } title：标题  label：label 对应得 key 字段
 * 7   buttons     [Array]                         底部按钮 （新增、显示更多等）
 * 7.1 { title: "", type: "", click：'' } title：标题  type: add 新增 more 显示更多 点击事件类型 默认 click  handleEvent 事件返回
 * 8   change      [Function]                      选择事件

 * other 等一些 el-select 支持属性
 * filterable  [Boolean]                       是否支持搜索
 * clearable   [Boolean]                       是否支持删除
 * disabled    [Boolean]                       是否禁用
 */

export default {
  name: "ComponentSelectRemote",
  mixins: [mixin],
  components: { Dialog: () => import("@/components/Dialog") },
  directives: { "select-load": directives("load-remote") },
  props: {
    size: {
      type: String,
      default: "mini",
    },
    curKey:{
      type:String
    }
  },
  data() {
    return {
      echoData: [],
      dialogOptions: {}, // 一些弹窗配置
      debounce: null,
      body: {
        filter: "",
        page: 1,
        size:
          Object.prototype.toString.call(this.option.downLoad) === "[object Number]"
            ? this.option.downLoad
            : 15, // 默认
      }, // 请求体
      cbObj: {},
      inAxios: 0,
      visible: false,
      data: [], // 显示数据
      loading: true, // 初始化加载中
      remoteLoad: false, // 远程加载中
      downLoading: false, // 上拉加载中
      canAdd: true, // 是否能上拉加载
      showNoData: false,
      showStyle: {
        height: "34px",
        "line-height": "34px",
        "text-align": "center",
        color: "#999",
        cursor: "text",
      },
      loadStyle: {
        height: "34px",
        "line-height": "34px",
        "text-align": "left",
        color: "#999",
        cursor: "text",
      },
      isKeying:false,
    };
  },
  watch: {
    "option.showItem": {
      handler(val) {
        this.echoData = val;
      },
      deep: true,
      immediate: true,
    },
    value: {
      handler(val, oldVal) {
        if (
          this.multiple && isArray(oldVal) ? (val?.length || 0) > oldVal.length : true
        ) {
          this.loadMote();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    buttons() {
      const defaultButton = {
        add: {
          icon: "el-icon-plus",
          click: "add",
          title: "新增",
        },
        more: {
          icon: "el-icon-more",
          click: "more",
          title: "显示更多",
        },
      };
      return this.option.buttons?.map?.((x) => ({
        ...x,
        ...defaultButton[x.type],
      }));
    },
    sunyunSelect() {
      const item = this.buttons?.find?.((x) => x.type === "more");
      return {
        suffix: item?.icon || "",
        click: item ? () => this.handleEvent(item) : undefined,
      };
    },
  },
  methods: {
    async remoteApi(req = {}) {
      let data = [];
      try {
        const { page: pageNum, size: pageSize, param = "", value } = req; // param 搜索值 value 回显搜索值
        const {
          remote,
          remoteKey = "rows",
          remoteBody = {},
          remoteFilter = "query",
          remoteEcho,
        } = this.option;
        let body = { pageNum, pageSize };
        let defaultBody = remoteBody;
        if (typeof remoteBody === "function") {
          defaultBody = remoteBody(req);
        }
        let params = {};
        if (req.hasOwnProperty("value")) {
          params[remoteEcho || this.keyName] = value;
        } else if (param.trim()) {
          params[remoteFilter] = param.trim();
        }
        body = { ...body, ...params, ...defaultBody };
        const res = await remote(body);
        if (typeof remoteKey === "function") {
          data = remoteKey(res);
        } else {
          data = res[remoteKey];
        }
      } catch (error) {
        console.log("remoteApi error", error);
      }
      return data;
    },
    // 回显
    async loadMote() {
      if (
        !this.visible &&
        haveContent(this.value) &&
        !this.getRadioData(this.value) &&
        (isArray(this.value) ? this.value.length : true) &&
        !this.option.noEcho
      ) {
        await this.$nextTick();
        let data =
          isArray(this.option.showItem) && this.option.showItem?.length
            ? this.option.showItem
            : [];
        if (!data.length) {
          console.log("this.option.noEcho", this.option.noEcho);
          data = await this.remoteApi({
            page: 1,
            size: this.multiple ? this.selModel?.length : 1,
            value: this.selModel,
          });
        }
        if (isArray(data)) {
          this.data = data;
          await this.$nextTick();
          this.data = [];
        }
      }
      this.loading = false;
    },
    // 上拉加载
    async downLoad() {
      if (this.canAdd) {
        this.canAdd = false;
        this.body.page = this.body.page + 1;
        const res = await this.getList();
        if (res === "fail") {
          this.canAdd = true;
          return false;
        }
        this.data = [...this.data, ...res];
      }
    },
    async visibleChange(visible) {
      this.visible = visible;
      if (visible) {
        // 添加键盘事件监听
        document.addEventListener('keyup', this.handleKeyUp);
        if (!this.option.noLoad) {
          this.remoteMethod();
        }
        this.$emit('handleChooseCallback');
      } else {
        // 移除键盘事件监听
        document.removeEventListener('keyup', this.handleKeyUp);
        await this.$nextTick();
        this.canAdd = false;
        this.data = [];
        this.isKeying = false;
      }
    },
    handleSelectKeydown(e){
      this.$emit('handleSelectKeydown',e);
    },
    handleKeyUp(e) {
      const selectDropdown = document.querySelector('.my-select-dropdown');
      if (!selectDropdown) return;
 
      const items = selectDropdown.querySelectorAll('.el-scrollbar__view el-option');

      switch (e.keyCode) {
        case 38: // Up
          // 实现上键选择上一个选项的逻辑
          console.log('来上了吗');
          this.isKeying = true;
          break;
        case 40: 
          {
            // Down
            // 实现下键选择下一个选项的逻辑
            this.isKeying = true;
            console.log('来下了吗');
            this.downLoad();
          }
          break;
        default:
          break;
      }
    },
    // 模糊查询搜索
    async remoteMethod(val = "") {
      this.body.page = 1;
      if (this.option.labels) {
        this.downLoading = true;
      } else {
        this.remoteLoad = true;
      }
      this.showNoData = false;
      this.cbObj = { param: strEscape(val) };
      const res = await this.getList();
      if (res === "fail") {
        return false;
      }
      this.remoteLoad = false;
      this.data = res;
      this.showNoData = this.data?.length === 0;
    },
    async getList() {
      try {
        this.inAxios = this.inAxios + 1;
        const num = this.inAxios;
        const res = await this.remoteApi({
          ...deepCopy(this.body),
          ...this.cbObj,
        });
        //默认选中第一项
        if(res.length>0 && this.visible && this.$refs.remoteSelect.hoverIndex < 0&&!this.selModel){
          setTimeout(()=>{
            let index = 0;
            if(!this.cbObj.param){
              index = 1;
            }
            console.log(index);
            this.$refs.remoteSelect.hoverIndex = index;
          },500)
        }
        if (this.inAxios === num) {
          this.canAdd = !(res.length < this.body.size);
          this.downLoading = this.canAdd;
          return res; // 只返回最后一次请求数据，防止data 数据混乱
        }
      } catch (error) { }
      return "fail";
    },
    async handleEvent({ click, option } = {}, row) {
      switch (click) {
        case "more":
          if (this.option.disabled) return;
          this.dialogOptions = { ...(deepCopy(option)), show: true };
          this.$emit('handleChooseCallback');
          break;
        case "getSelectData":
          const {
            check,
            radioSelect,
            rowKey,
            mutiSelect,
          } = this.dialogOptions.formData.table;
          const { tableChange, curTableIndex, remoteKey = "rows" } = this.option;

          if (typeof remoteKey === "function") {
            this.data = remoteKey({ rows: check });
          } else {
            this.data = check;
          }
          //单选直接赋值
          if (radioSelect) {
            //单选
            this.selModel = check[0][rowKey];
            this.$emit("selectChange", '', check[0]);
          } else if (mutiSelect && !tableChange) {
            //多选
            this.selModel = check.map((item) => item[rowKey]);
          }
          if (typeof tableChange === "function") {
            if (radioSelect) {
              tableChange(this.selModel, { check: check[0], curTableIndex });
            } else if (mutiSelect) {
              tableChange("", { check, curTableIndex });
            }
          }
          await this.$nextTick();
          this.data = [];
          break;
        default:
          break;
      }
    },
    change(val) {
      if (this.multiple) {
        this.$nextTick(() => {
          const tags = this.$refs.remoteSelect.$el.querySelector(".el-select__tags span");
          tags.scrollTo({ left: tags.scrollWidth, behavior: "smooth" });
        });
      }
      const obj = this.getRadioData(val);
      if (typeof this.option.change === "function") {
        this.option.change(val, obj, deepCopy(this.option));
      }
      this.$emit("selectChange", val, obj, deepCopy(this.option));
    },
  },
};
</script>
<style lang="scss" scoped>
.remote-hide {
  ::v-deep .el-select__tags {
    visibility: hidden;
  }

  ::v-deep .el-input__inner {
    color: #f5f7fa;
  }
}

.sys-remote {
  width: 100%;

  ::v-deep .el-icon-more {
    cursor: pointer;

    &:hover {
      color: #409eff !important;
    }
  }

  ::v-deep .el-select__tags {
    left: 2px;

    & span:not(.el-tag, .el-select__tags-text):first-child {
      display: flex;
      overflow-x: auto;
      scrollbar-width: none;
      /* Firefox */
      max-width: calc(100% - 55px) !important;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  ::v-deep .el-loading-mask {
    border: 1px solid #dcdfe6;
    border-radius: 4px;

    .el-loading-spinner {
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      margin: 0;

      .el-icon-loading,
      .el-loading-text {
        color: #555555;
        margin-left: 10px;
      }
    }
  }

  ::v-deep .el-icon-more {
    cursor: pointer;

    &:hover {
      color: #409eff !important;
    }
  }
}

.load-remote {
  &.is-multiple {
    padding-right: 20px;

    .static {
      padding-right: 20px;
    }
  }

  &.is-multiple {
    padding-right: 20px;

    .static,
    .static-bottom {
      z-index: 10;
      padding-right: 20px;

      .content {
        text-align: center;
        flex: 1;
        width: 50%;
      }
    }
  }
  .el-select-dropdown__item.hover{
    background:#e3e3e3!important;
  }

  .static {
    opacity: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: rgba($color: #f3f4f5, $alpha: 1);
    border-top: 6px solid #ffffff;
    height: 38px;
    width: 100%;
    padding: 0 20px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      min-width: 90px;
      flex: 1;
      font-size: 14px;
      font-weight: bold;
      color: #606266;
      overflow: hidden;
      white-space: nowrap; // 默认不换行；
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis; //溢出用省略号显示
    }
  }
  .sticky{
    position:inherit!important;
  }
  .static-bottom {
    bottom: 0;
    z-index: 2;
    border-top: solid 1px #dfe4ed;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
  }

  .selected .label .headTitle {
    color: #1890ff !important;
  }

  .selected .label2 .headTitle {
    font-weight: initial !important;
  }

  .el-select-dropdown__item {
    padding-right: 20px;
  }

  .el-select-dropdown__item.hover {
    background-color: #f8f8f9;
  }

  .el-select-dropdown__item.is-disabled {
    background: #f4f5f6;
    opacity: 0.75;
  }

  .label,
  .label2 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      flex: 1;
      font-size: 14px;
      color: #606266;
      overflow: hidden;
      white-space: nowrap; // 默认不换行；
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis; //溢出用省略号显示
    }
  }
}
</style>
<style>
/* 确保自定义下拉类的样式不被覆盖 */
.my-select-dropdown {
  z-index: 9999 !important;
}
</style>
