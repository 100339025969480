<!-- 表格键盘导航 -->
<template>
    <div class="table-box">
        <el-form
        ref="form"
        :model="form"
        label-position="right"
        class="ken-detail-form"
        :rules="validRules"
        size="mini"
      >
            <el-row>
                <el-col :span="24">
                    <vxe-table
                        class="table"
                        ref="keyBoardTable"
                        :data="form.details"
                        border
                        resizable
                        keep-source
                        show-overflow
                        :loading="tloading"
                        :show-footer="showFooter"
                        :max-height="tableHeight"
                        :height="tableHeight"
                        show-header-overflow
                        :checkbox-config="{ trigger: 'row' }"
                        :row-config="{ isHover: true }"
                        :sort-config="{ trigger: 'cell' }"
                        :scroll-y="{ enabled: true }"
                        :footer-method="footerMethod"
                        @cell-click="handleCellClick"
                        @checkbox-change="handleSelectionChange"
                        @checkbox-all="handleSelectionChange"
                    >
                        <vxe-column type="checkbox" width="50" :fixed="isHadFixed" align="center" v-if="isShowCheckbox"></vxe-column>
                        <vxe-column
                            align="center"
                            :title="tableCellLabel"
                            width="80"
                            :fixed="isHadFixed"
                        >
                        <template #header>
                            <i class="el-icon-s-operation" style="font-size: 18px;cursor:pointer;" @click="handleTableColumnConfig" v-if="hasTableSetting"></i>
                            <span v-else>{{ tableCellLabel }}</span>
                        </template>
                        <template #default="scope">
                            <div
                                v-if="isShowTableIndex"
                                @mouseenter="cellMouseEnter(scope.row)"
                                @mouseleave="cellMouseLeave(scope.row)"
                                >
                                <i
                                    v-show="scope.row.hoverRow"
                                    @click="row('push', scope.rowIndex)"
                                    class="el-icon-circle-plus operatePush"
                                ></i>
                                <i
                                    v-show="scope.row.hoverRow"
                                    class="el-icon-remove operateDel"
                                    @click="row('del', scope.rowIndex)"
                                ></i>
                                <div v-show="!scope.row.hoverRow">
                                    {{ scope.rowIndex + 1 }}
                                </div>
                            </div>
                            <div v-if="!isShowTableIndex">
                                {{ scope.rowIndex + 1 }}
                            </div>
                        </template>
                        </vxe-column>
                        <template v-for="(item,iIndex) in gridColumns">
                            <template v-if="item.children">
                                <vxe-colgroup align="center" :field="'group'+iIndex" :title="item.title">
                                    <template v-for="pro in item.children">
                                        <vxe-column
                                            :title="pro.title"
                                            :width="pro.width"
                                            :align="pro.align||'center'"
                                            :field="pro.key"
                                            :fixed="pro.fixed"
                                            :key="pro.key"
                                            v-if="pro.fieldIsDisplay"
                                        >
                                            <template slot="header" v-if="pro.isRequired">
                                                <i style="color: #ff4949">* </i>
                                                <span>{{ pro.title }}</span>
                                            </template>
                                            <template slot-scope="scope">
                                                <span v-if="!pro.type">{{ scope.row[pro.key] }}</span>
                                                <el-form-item
                                                :prop="'details.'+scope.rowIndex+'.'+pro.key"
                                                :rules="validRules[pro.key]"
                                                :ref="pro.key+'_'+scope.rowIndex +'_'+pro.columnindex+'Form'"
                                                >
                                                    <el-input
                                                        v-model="scope.row[pro.key]"
                                                        v-if="pro.type && pro.type.indexOf('input')>-1"
                                                        :type="pro.type=='inputText'?'text':'number'"
                                                        :maxlength="pro.maxlength"
                                                        :disabled="pro.disabled"
                                                        size="mini"
                                                        :ref="pro.key + '_' + scope.rowIndex + '_' + pro.columnindex"
                                                        @input="handleInput($event, scope, pro)"
                                                        @blur="handleBlur($event, scope, pro)"
                                                        @keydown.native="
                                                            handleInputKeydownEvent($event, scope, pro, 'all')
                                                        "
                                                        @keydown.native.up.prevent="
                                                            handleInputKeydownEvent($event, scope, pro, 'up')
                                                        "
                                                        @keydown.native.down.prevent="
                                                            handleInputKeydownEvent($event, scope, pro, 'down')
                                                        "
                                                        @keydown.native.left.prevent="
                                                            handleInputKeydownEvent($event, scope, pro, 'left')
                                                        "
                                                        @keydown.native.right.prevent="
                                                            handleInputKeydownEvent($event, scope, pro, 'right')
                                                        "
                                                    >
                                                        <i v-if="pro.icon" slot="suffix" :class="pro.icon" style="cursor: pointer;" @click="handleClickDefineInputIcon(scope,pro)"/>
                                                    </el-input>
                                                    <SelectRemote
                                                        v-if="pro.type=='selectRemote'"
                                                        size="mini"
                                                        :disabled="pro.disabled"
                                                        v-model="scope.row[pro.key]"
                                                        :option="pro.options"
                                                        :curKey="pro.key"
                                                        :filterable="pro.filterable||true"
                                                        :placeholder="pro.placeholder||('选择'+pro.title)"
                                                        :ref="pro.key+'_'+scope.rowIndex +'_'+pro.columnindex"
                                                        @selectChange="(v,row)=>handleSelectRemoteChange(v,scope,pro)"
                                                        @handleSelectKeydown="handleTableKeydown($event, scope.row,pro.key,scope.rowIndex,pro.columnindex)"
                                                        @handleChooseCallback="handleSelectRemoteChoose(scope,pro)"
                                                    >
                                                    </SelectRemote>
                                                    <SelectLocal
                                                        v-if="pro.type=='selectLocal'"
                                                        size="mini"
                                                        :disabled="pro.disabled"
                                                        v-model="scope.row[pro.key]"
                                                        :ref="pro.key+'_'+scope.rowIndex +'_'+pro.columnindex"
                                                        :option="{data:scope.row[pro.optionListName],label: pro.optionLabel,value: pro.optionValue}"
                                                        @blur="handleSelectLocalBlur(v,scope,pro)"
                                                        @selectChange="(v,row)=>handleSelectLocalChange(v,scope,pro)"
                                                        @handleSelectKeydown="handleTableKeydown($event,scope.row,pro.key,scope.rowIndex,pro.columnindex)"
                                                    ></SelectLocal>
                                                    <el-select
                                                        v-if="pro.type=='select'"
                                                        :disabled="pro.disabled"
                                                        v-model="scope.row[pro.key]"
                                                        size="mini"
                                                        :ref="pro.key+'_'+scope.rowIndex +'_'+pro.columnindex"
                                                        @keydown.native="handleTableKeydown($event,scope.row,pro.key,scope.rowIndex,pro.columnindex)"
                                                        @change="hanldeSelectChange($event,scope,pro)"
                                                    >
                                                        <el-option v-for="(pro,index) in scope.row[pro.optionListName]"
                                                            :key="index"
                                                            :value="pro[pro.optionValue]"
                                                            :label="pro[pro.optionLabel]">
                                                        </el-option>
                                                    </el-select>
                                                    <el-date-picker
                                                        v-if="pro.type&&pro.type.indexOf('date')>-1"
                                                        :type="pro.type"
                                                        aria-setsize="mini"
                                                        :style="{width:(pro.width-20)+'px'}"
                                                        v-model="scope.row[pro.key]"
                                                        :placeholder="pro.placeholder||'选择日期'"
                                                        :disabled="pro.disabled"
                                                        :value-format="pro.valueFormat||'yyyy-MM-dd'"
                                                        :ref="pro.key+'_'+scope.rowIndex +'_'+pro.columnindex"
                                                        @keydown.native="handleTableKeydown($event,scope.row,pro.key, scope.rowIndex,pro.columnindex)"
                                                        >
                                                    </el-date-picker>
                                                </el-form-item>
                                            </template>
                                        </vxe-column>
                                    </template>
                                </vxe-colgroup>
                            </template>
                            <template v-else>
                                <vxe-column
                                    :title="item.title"
                                    :width="item.width"
                                    :align="item.align||'center'"
                                    :field="item.key"
                                    :fixed="item.fixed"
                                    :key="item.key"
                                    v-if="item.fieldIsDisplay"
                                >
                                    <template slot="header" v-if="item.isRequired">
                                        <i style="color: #ff4949">* </i>
                                        <span>{{ item.title }}</span>
                                    </template>
                                    <template slot-scope="scope">
                                        <span v-if="!item.type">{{ scope.row[item.key] }}</span>
                                        <el-form-item
                                        :prop="'details.'+scope.rowIndex+'.'+item.key"
                                        :rules="validRules[item.key]"
                                        :ref="item.key+'_'+scope.rowIndex +'_'+item.columnindex+'Form'"
                                        >
                                            <el-input
                                                v-model="scope.row[item.key]"
                                                v-if="item.type && item.type.indexOf('input')>-1"
                                                :type="item.type=='inputText'?'text':'number'"
                                                :maxlength="item.maxlength"
                                                :disabled="item.disabled"
                                                size="mini"
                                                :ref="item.key + '_' + scope.rowIndex + '_' + item.columnindex"
                                                @input="handleInput($event, scope, item)"
                                                @blur="handleBlur($event, scope, item)"
                                                @keydown.native="
                                                    handleInputKeydownEvent($event, scope, item, 'all')
                                                "
                                                @keydown.native.up.prevent="
                                                    handleInputKeydownEvent($event, scope, item, 'up')
                                                "
                                                @keydown.native.down.prevent="
                                                    handleInputKeydownEvent($event, scope, item, 'down')
                                                "
                                                @keydown.native.left.prevent="
                                                    handleInputKeydownEvent($event, scope, item, 'left')
                                                "
                                                @keydown.native.right.prevent="
                                                    handleInputKeydownEvent($event, scope, item, 'right')
                                                "
                                            >
                                                <i v-if="item.icon" slot="suffix" :class="item.icon" style="cursor: pointer;" @click="handleClickDefineInputIcon(scope,item)"/>
                                            </el-input>
                                            <SelectRemote
                                                v-if="item.type=='selectRemote'"
                                                size="mini"
                                                :disabled="item.disabled"
                                                v-model="scope.row[item.key]"
                                                :option="item.options"
                                                :curKey="item.key"
                                                :filterable="item.filterable||true"
                                                :placeholder="item.placeholder||('选择'+item.title)"
                                                :ref="item.key+'_'+scope.rowIndex +'_'+item.columnindex"
                                                @selectChange="(v,row)=>handleSelectRemoteChange(v,scope,item)"
                                                @handleSelectKeydown="handleTableKeydown($event, scope.row,item.key,scope.rowIndex,item.columnindex)"
                                                @handleChooseCallback="handleSelectRemoteChoose(scope,item)"
                                            >
                                            </SelectRemote>
                                            <SelectLocal
                                                v-if="item.type=='selectLocal'"
                                                size="mini"
                                                :disabled="item.disabled"
                                                v-model="scope.row[item.key]"
                                                :ref="item.key+'_'+scope.rowIndex +'_'+item.columnindex"
                                                :option="{data:scope.row[item.optionListName],label: item.optionLabel,value: item.optionValue}"
                                                @blur="handleSelectLocalBlur(v,scope,item)"
                                                @selectChange="(v,row)=>handleSelectLocalChange(v,scope,item)"
                                                @handleSelectKeydown="handleTableKeydown($event,scope.row,item.key,scope.rowIndex,item.columnindex)"
                                            ></SelectLocal>
                                            <el-select
                                                v-if="item.type=='select'"
                                                :disabled="item.disabled"
                                                v-model="scope.row[item.key]"
                                                size="mini"
                                                :ref="item.key+'_'+scope.rowIndex +'_'+item.columnindex"
                                                @keydown.native="handleTableKeydown($event,scope.row,item.key,scope.rowIndex,item.columnindex)"
                                                @change="hanldeSelectChange($event,scope,item)"
                                            >
                                                <el-option v-for="(pro,index) in scope.row[item.optionListName]"
                                                    :key="index"
                                                    :value="pro[item.optionValue]"
                                                    :label="pro[item.optionLabel]">
                                                </el-option>
                                            </el-select>
                                            <el-date-picker
                                                v-if="item.type&&item.type.indexOf('date')>-1"
                                                :type="item.type"
                                                aria-setsize="mini"
                                                :style="{width:(item.width-20)+'px'}"
                                                v-model="scope.row[item.key]"
                                                :placeholder="item.placeholder||'选择日期'"
                                                :disabled="item.disabled"
                                                :value-format="item.valueFormat||'yyyy-MM-dd'"
                                                :ref="item.key+'_'+scope.rowIndex +'_'+item.columnindex"
                                                @keydown.native="handleTableKeydown($event,scope.row,item.key, scope.rowIndex,item.columnindex)"
                                                >
                                            </el-date-picker>
                                        </el-form-item>
                                    </template>
                                </vxe-column>
                            </template>
                        </template>
                    </vxe-table>
                </el-col>
            </el-row>
        </el-form>
        <!-- 弹窗 -->
        <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
</template>
<script>
import Dialog from "@/components/Dialog";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import {scroll,destroyScroll} from "@/views/components/bill/public-layout.js"; //方法统一存放的地方
import SelectGoods from "@/views/components/SelectGoods/SelectGoods";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import tableKeydown from "@/utils/mixin/tableKeydown";//表格键盘导航
import tableColulumnConfig from "@/utils/mixin/tableColumnConfig";
import {inputLimit} from "@/utils/numFormatting";//数字类型文本框输入限制
import { arrayObjectCopy } from "@/utils";
export default {
    name:'keyBoardTable',
    components:{
        Dialog,
        SelectRemote,
        SelectGoods,
        SelectLocal
    },
    mixins:[tableKeydown,tableColulumnConfig],
    props:{
        showFooter:{
            type:Boolean,
            default:true
        },
        //表格高度
        tableHeight:{
            type:String,
            default:'600px'
        },
        //是否显示checkbox
        isShowCheckbox:{
            type:Boolean,
            default:false
        },
        //是否显示表格序号栏
        isShowTableIndex:{
            type:Boolean,
            default:true
        },
        //表格是否禁用
        isDisabled:{
            type:Boolean,
            default:false,
        },
        //表格列详情
        /*
            title 列名称
            width 列宽
            align 列文字显示
            key 列key
            isRequired 是否必填
            type  列编辑类型   inputText,inputNumber,selectRemote,selectLocal,select,date
            maxlength 如果是文本框，最长输入长度
            disabled 是否可操作
            columnindex 列索引
            options 列type类型为selectRemote或者selectLocal时，配置项
            filterable 列type类型为selectRemote时，是否可过滤
            placeholder 列文字提示
            optionListName 列type为select时，选项列表的名称
            optionLabel 列type为select时,option label
            optionValue  列type为select时,option value
            valueFormat 列type为时间日期类型时，默认的格式  
            intMax 列type为inputNumber类型时整数最大位数
            dotMax 列type为inputNumber类型时小数最大位数
            fieldIsDisplay 列是否显示
        */
        tableColumns:{
            type:Array,
            default:[]
        },
        //备份一下原始列表数据
        tableColumnsCopy:{
            type:Array,
            default:[]
        },
        //是否可列配置
        hasTableSetting:{
            type:Boolean,
            default:true
        },
        //是否表格内容禁用
        isTableContentDisabled:{
            type:Boolean,
            default:false
        },
        isCreatedInit:{
            type:Boolean,
            default:false
        },
        uuid:{
            type:String,
        }
    },
    data(){
        return {
            tableCellLabel:'序号',
            form:{
                details:[]//表格数据
            },
            canKeyTableColumnList:[],
            validRules:{},//表格验证规则
            isHadFixed:'',
            dialogOptions:{},
            //原始列表数据
            originColumns:[],
            tableKey:Date.now(),
            isKeyBoardTableComponent:true,
            isFirstInit:true,
            gridColumnsCopy:[],
            tloading:false,
            gridColumnsShow:[]
        }
    },
    watch:{
        'form.details':{
            handler(val){
                this.$emit('handleSyncTableData',val);
                this.$refs?.keyBoardTable?.updateFooter();
                // console.log(this.$refs.keyBoardTable,'来这了吗');
            },
            immediate:true,
            deep:true
        },
        isTableContentDisabled:{
            handler(val){
                console.log(this.isTableContentDisabled,'isTableContentDisabled watch');
                setTimeout(()=>{
                    if(val){
                        this.gridColumns.forEach(item=>item.disabled = true);
                    }else{
                        this.gridColumns.forEach(item=>{
                            if(!item.originDisabled){
                                item.disabled = false;
                            }
                        });
                    }
                    this.$refs?.keyBoardTable?.refreshColumn();
                },200);
            },
        }
    },
    created(){
        this.isFirstInit = true;
        console.log('来着了吗created');
        //整体刷新页面的时候也需要获取一下列信息
        let pagePath = this.$route.path
        let path = JSON.parse(sessionStorage.getItem('beforeunload-path'))
        if (pagePath === path) {
            console.log('刷新')
            this.handleTableSettingInit()
            this.getColumnConfigDetail();
            sessionStorage.removeItem('beforeunload-path') // 判断完之后，移除刷新时缓存的路由，以免造成对其他页面的路由影响
        } else {
            console.log('不是刷新')
        }
        setTimeout(()=>{
            if(this.isCreatedInit){
                this.handleTableSettingInit()
                this.getColumnConfigDetail(this.uuid); 
            }
        },200);
    },
    activated(){
        console.log('来着了吗activated');
        this.handleTableSettingInit()
        this.getColumnConfigDetail(this.uuid);
    },
    methods:{
        inputLimit,
        //监听滚动事件
        scroll,
        //移除监听滚动事件
        destroyScroll,
        //表格的一些初始化操作
        async handleInit(){
            this.validRules = {};
            this.isHadFixed = '';
            let obj = {
                hoverRow:false
            }
            //表格键盘导航时需要
            this.gridColumns.forEach((item,index)=>{
                item.columnindex = index+1;
                if(item.fieldCaption){
                    item.key=item.fieldName;
                    item.title=item.fieldCustomCaption;
                    item.width=item.fieldCustomWidth;
                    item.fixed='';
                    if(item.fieldFixedFront){
                        item.fixed='left';
                        this.isHadFixed = 'left';
                    }
                    if(item.fieldFixedBack){
                        item.fixed='right';
                    }
                }else{
                    if(!item.fieldIsDisplay){
                        item.fieldIsDisplay = true;//列默认显示可见
                    }
                }
                if(item.type=='selectRemote'||item.type=='selectLocal'){
                    item.componentRefName="remoteSelect";
                }
                if(item.key=='remark'){
                    item.maxlength=80;
                }
                //表格的一些字段校验
                if(item.isRequired){
                    this.validRules[item.key] = [
                        {required:true,message:item.title+'不能为空',trigger:'blur'}
                    ]
                }
                if(item.type!='inputNumber'){
                    obj[item.key]='';
                }
            })
            if(this.isFirstInit && this.form.details.length == 0){
                this.gridColumnsCopy = arrayObjectCopy(this.gridColumns);
                this.isFirstInit = false;
                this.form.details.push(obj);
            }
            this.canKeyTableColumnList = this.gridColumns.filter(item=>item.type);
            console.log(this.isTableContentDisabled,'isTableContentDisabled init');
            console.log(this.gridColumns,'gridColumns')
            console.log(this.canKeyTableColumnList,24324234234234)

            this.gridColumnsShow = arrayObjectCopy(this.gridColumns);
            this.gridColumns = this.gridColumns.map(item=>{
                if(item.fieldCaption.indexOf('|')>-1){
                    let obj = Object.assign({},item);
                    let outObj = {
                        title:item.fieldCaption.split('|')[0],
                        fieldCaption:item.fieldCaption.split('|')[0],
                        fieldCustomCaption:item.fieldCaption.split('|')[0],
                        children:[
                            {
                                ...obj,
                                title:item.fieldCaption.split('|')[1],
                                fieldCustomCaption:item.fieldCaption.split('|')[1],
                                fieldCaption:item.fieldCaption.split('|')[1]
                            }
                        ]
                    }
                    return outObj;
                }
                return item;
            });
            this.gridColumns = this.mergeConsecutiveDuplicates(this.gridColumns);
            this.gridColumns.forEach(item=>{
                if (item.key == "unitQty") {
                    item.inputConfig = 1;
                }
                if (item.key == "unitPrice" || item.key == "unitTaxPrice") {
                    item.inputConfig = 2;
                }
                if (item.key == "unitPriceTaxMoney"|| item.key.indexOf("Money") > -1) {
                    item.inputConfig = 3;
                }
                if(item.children){
                    item.children.forEach(pro=>{
                        if (pro.key == "unitQty") {
                            pro.inputConfig = 1;
                        }
                        if (pro.key == "unitPrice" || pro.key == "unitTaxPrice") {
                            pro.inputConfig = 2;
                        }
                        if (pro.key == "unitPriceTaxMoney"|| pro.key.indexOf("Money") > -1) {
                            pro.inputConfig = 3;
                        }
                    })
                }

            })
            console.log(this.gridColumns,'最后面');
            await this.$refs?.keyBoardTable?.refreshColumn();
        },
        mergeConsecutiveDuplicates(arr) {
            let lastElement = null;
            return arr.reduce((acc, current) => {
                if (lastElement && lastElement.fieldCaption === current.fieldCaption) {
                // 如果上一个元素与当前元素有相同的id，则合并children
                lastElement.children = [...(lastElement.children || []), ...(current.children || [])];
                } else {
                // 如果不是连续的重复元素，则直接将当前元素加入结果数组
                acc.push(current);
                }
                // 更新lastElement为当前元素，为下一次迭代做准
                lastElement = current;
                return acc;
            }, []);
        },
        handleTableSettingInit(){
            this.originColumns = [];
            //console.log(this.tableColumns);
            this.originColumns = this.tableColumns;
        },
        //为表格赋值
        handleCallbackData(data){
            this.form.details=data;
            if(data.length==0){
                this.handleInit();
            }
        },
        handleCellClick(params){
            this.$emit('handleEvent', 'tableIndex', params.rowIndex);
        },
        handleTableColumnConfig(){;
            let tableData = arrayObjectCopy(this.gridColumnsShow);
            console.log(tableData);
            let originData = arrayObjectCopy(this.tableColumnsCopy);
            this.dialogOptions = {
                title: "列表配置",
                width: 800,
                show: true,
                type: "tableColumnConfig",
                data:{
                    tableData,
                    originData
                }
            }
            //this.$emit('handleTableColumnConfig');
        },
        async handleEvent(type,row) {
            switch(type){
                case "dialogChange":
                    if(row.type=='tableColumnConfig'){
                        //修改列配置
                        this.handleSaveColumnConfig(row.data.tableData);
                    }
                    break;
                case 'dialogInit':
                    if(row.type=='tableColumnConfig'){
                    //console.log(row)
                    this.handleSaveColumnConfig(row.data.originData);
                    }
                    break;
                default:
                    break;
            }
        },
        //表格行hover时,显示操作加减号
        cellMouseEnter(row) {
            if(this.isDisabled){
                return;
            }
            let eleIndex = this.form.details.indexOf(row);
            this.form.details[eleIndex].hoverRow = true;
            this.tableCellLabel = "操作";
            this.$emit('handleEvent', 'tableIndex', eleIndex);
        },
        //表格行离开hover时,不显示操作加减号,显示序号
        cellMouseLeave() {
            // 移除hover的事件
            if(this.isDisabled){
                return;
            }
            for (let index = 0; index < this.form.details.length; index++) {
                const element = this.form.details[index];
                element["hoverRow"] = false;
            }
            this.tableCellLabel = "序号";
        },
        //增减表格行
        row(name,index){
            if (name === 'push') {
                this.form.details.splice(index + 1, 0, {
                hoverRow: false,
                })
            } else {
                if (this.form.details.length <= 1) {
                    this.$set(this.form.details, index, [])
                    return
                }
                this.form.details.splice(index, 1)
            }
        },
        //合计行
        footerMethod({ columns, data }) {
            // return [
            //     ['合计', '', '', '282', '']
            // ]
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                sums[index] = "合计";
                return;
                }
                if (
                column.property === 'unitQty' || //数量
                column.property === 'unitTaxMoney' || //税额
                column.property === 'unitMoney' || //价税合计
                column.property === 'unitPriceTaxMoney' || //价税合计
                column.property === 'attachMoney' || 
                column.property === 'basUnitAccountQty' || //数量
                column.property === 'basUnitQty' || //数量
                column.property === 'unitPurPrice' || //数量
                column.property === 'unitPurMoney' || //数量
                column.property === 'unitSalePrice' || //数量
                column.property === 'unitSaleMoney' || //数量
                column.property === 'basUnitAdjustQty' || //数量
                column.property === 'basUnitAdjustPurPrice' || //数量
                column.property === 'basUnitAdjustSalePrice' || //数量
                column.property === 'basUnitLoseQty' || //数量
                column.property === 'basUnitLosePurPrice' || //数量
                column.property === 'basUnitLoseSalePrice' || //数量
                column.property === 'importUnitQty' || //数量
                column.property === 'unitPrice' 
                ) {
                //附加费
                const values = data.filter(
                    (item) => !(item[`${column.property}`] === undefined)
                );
                const values2 = values.map((i) => Number(i[`${column.property}`]));

                const config = this.$store.state.permission.inputConfig;
                const keys = {
                    1: "system_BUSINESS_DATA_CONFIG_11", // 数量
                    2: "system_BUSINESS_DATA_CONFIG_12", // 单价
                    3: "system_BUSINESS_DATA_CONFIG_13", // 金额
                };
                let type=1;
                if (column.property == "unitQty") {
                    type = 1;
                }
                if (column.property == "unitPrice" || column.property == "unitTaxPrice") {
                    type = 2;
                }
                if (column.property == "unitPriceTaxMoney"|| column.property.indexOf("Money") > -1) {
                    type = 3;
                }
                const key = keys[type];
                const pNum = config[key] || 3;
                
                sums[index] = values2
                    .reduce((prev, curr) => {
                    return prev + curr;
                    }, 0)
                    .toFixed(pNum);
                }
            });
            let arr = [];
            arr.push(sums);
            //console.log(arr)
            return arr;
        },
        reloadTable(){
            this.$refs.keyBoardTable.reload();
        },
        //表格复选框选中回调
        handleSelectionChange(){
            const selections = this.$refs.keyBoardTable.getCheckboxRecords();
            this.$emit('handleSelectionChange',selections);
        },
        //el-input keydown事件回调
        handleInputKeydownEvent(e,scope,item,type){
            if(item.type=='inputText'){
                if(type=='all'){
                    this.handleTableKeydown(e, scope.row,item.key,scope.rowIndex,item.columnindex);
                }
            }else{
                //number类型的input组件中上下键会对数字有增减功能，此处是为了阻止它的这个默认事件
                if(type!='all'){
                    this.handleTableKeydown(e, scope.row,item.key,scope.rowIndex,item.columnindex);
                }else{
                    if(e.key=='Enter'){
                        this.handleTableKeydown(e, scope.row,item.key,scope.rowIndex,item.columnindex);
                    }
                }
            }
        },
        //input框input事件
        handleInput(e,scope,item){
            if (item.type == "inputNumber") {
            //数字类型的输入框，默认最长可以输入8位整数。
            if (
                item.key == "unitQty" ||
                item.key == "unitPrice" ||
                item.key == "unitTaxPrice" ||
                item.key == "unitPriceTaxMoney"
            ) {
                let result = this.$syInput(item.inputConfig, e);
                this.$set(this.form.details[scope.rowIndex], item.key, result);
            }else{
                let curDotMax = 0;
                if(item.key.indexOf('Money')>-1){
                    curDotMax = 4;
                }else if(item.key=='taxRate'){
                    curDotMax = 2;
                }
                this.inputLimit(e, item.intMax||8, item.dotMax || curDotMax, this.form.details[scope.rowIndex], item.key);
            }
        }
        this.$emit("handleTableInput", e, scope, item);
        },
        handleClickDefineInputIcon(scope, item){
            if(item.disabled) return;
            this.$emit("handleClickDefineInputIcon", scope, item);
        },
        //input框blur事件
        handleBlur(e,scope,item){
            this.$emit('handleTableBlur',e,scope,item);
        },
        //input 格式回调
        hanldeInputFormatCallback(){},
        //selectLocal组件失焦事件
        handleSelectLocalBlur(value,scope,item){
            this.$emit('handleSelectLocalBlur',value,scope,item);
        },
        //selectLocal组件选中改变事件
        handleSelectLocalChange(value,scope,item){
            this.$emit('handleSelectLocalChange',value,scope,item);
        },
        //selectRemote组件选中改变事件
        handleSelectRemoteChange(value,scope,item){
            if(value&&item.isRequired){
                setTimeout(()=>{
                    this.$refs[item.key+'_'+scope.rowIndex +'_'+item.columnindex+'Form'][0].clearValidate();
                },100)
            }
            this.$emit('handleTableSelectRemoteChange',value,scope,item);
        },
        //当前操作selectRemote
        handleSelectRemoteChoose(scope,item){
            this.$emit('handleEvent', 'tableIndex', scope.rowIndex);
        },
        //select下拉框改变事件
        hanldeSelectChange(e,scope,item){
            if(e&&item.isRequired){
                setTimeout(()=>{
                    this.$refs[item.key+'_'+scope.rowIndex +'_'+item.columnindex+'Form'][0].clearValidate();
                },100)
            }
            this.$emit('handleTableSelectChange',e,scope,item);
        },
        //表格验证
        handleValidateTable(flag){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$emit('handleTableCallback',this.form.details,flag)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
//输入框原生属性
    /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
 ::v-deep .el-input__inner {
    line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>