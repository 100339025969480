<template>
  <div
    style="overflow: hidden; padding: 0px; display: flex; align-items: center"
  >
    <el-dropdown
      split-button
      :type="item.type || 'warning'"
      :icon="item.icon || 'el-icon-circle-check'"
      :placement="item.placement || 'bottom'"
      :trigger="item.trigger || 'click'"
      @click="handleEvent(item)"
      @command="handleEvent"
      size="mini"
    >
      {{ item.label }}
      <el-dropdown-menu slot="dropdown">
        <div v-if="item.print" class="table-page-print-view">
          <div style="padding: 0 8px">
            <el-button type="text" @click="addPrint">新建模板</el-button>
            <!-- <el-button
              type="text"
              v-if="item && item.print && item.print.config && item.print.config.nowPrint"
              @click="nowPrint"
            >
              直接打印
            </el-button> -->
          </div>
          <div class="print-view-sorcll">
            <template v-if="item.print.data">
              <div
                v-for="(printItem, i) in item.print.data"
                class="print-view-list"
                :key="i"
              >
                <div
                  class="print-view-list-title"
                  @click="handleEvent(item, printItem)"
                >
                  <i
                    class="el-icon-check"
                    :style="{
                      visibility: printItem.isDefault ? 'visible' : 'hidden',
                      marginRight: '3px',
                      color: '#1890ff'
                    }"
                  />{{ printItem.printTemplateName }}
                </div>
                <div class="print-view-list-botton">
                  <el-button
                    type="text"
                    @click="updatePrint(item.print, printItem)"
                  >
                    编辑
                  </el-button>
                  <el-button
                    type="text"
                    @click="lookPrint(item.print, printItem)"
                  >
                    预览
                  </el-button>
                </div>
              </div>
            </template>
            <div v-else class="print-view-loading">
              <span>加载中 <i class="el-icon-loading" /></span>
            </div>
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <PrintTemplateDetail
      v-if="printConfig"
      ref="PrintTemplateDetail"
      :isEdit="false"
      :printConfig="printConfig"
      style="
        position: fixed;
        left: -9999999px;
        top: -9999999px;
        z-index: 1;
        width: 100vw;
        height: 100vh;
      "
    />
  </div>
</template>
<script>
import { deepCopy } from '@/utils'
import { getTemplateList } from '@/api/system/system/printTemplate'
import printConfig from '@/components/tablePage/tableButtons/printConfig.js'

export default {
  name: 'TabelPageTableTopButton',
  components: {
    PrintTemplateDetail: () =>
      import('@/views/system/custom/printTemplate/detail')
  },
  model: { prop: 'value', event: 'Device' },
  props: {
    watchRoute: {
      type: Boolean,
      default: true
    },
    printTemplate: {
      type: Object,
      default: () => ({})
    },
    check: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      item: this.printTemplate,
      routerPath: '',
      printConfig: null
    }
  },
  async mounted () {
    this.loadPrint()
  },
  methods: {
    async loadPrint () {
      this.routerPath = printConfig[this.$route.path]
        ? this.$route.path
        : this.$route.meta?.activeMenu
      const config = printConfig[this.routerPath]
      if (config && this.printTemplate?.print) {
        const print = { ...this.printTemplate.print, data: [], config }
        try {
          const res = await getTemplateList({
            pageNum: 1,
            pageSize: 999999,
            isStop: false,
            routerPath: this.routerPath
          })
          print.data = res?.rows
        } catch (error) {}
        this.item = { ...this.printTemplate, print }
      }
    },
    async handleEvent (e = {}, row) {
      let printItem = row
      if (!row) {
        printItem = this.item.print.data?.find?.(x => x.isDefault)
        if (!printItem) {
          return this.$message.warning('无默认打印模板')
        }
      }
      this.setPrintConfig(this.item.print, printItem)
    },
    updatePrint (print, row) {
      // 编辑
      this.$router.push({
        name: 'printTemplateDetail',
        query: { printTemplateId: row.printTemplateId,form:this.$route.path}
      })
    },
    async setPrintConfig (print, row, lookPage) {
      this.printConfig = null
      if (!this.check?.length) {
        return this.$message.warning('请选择要执行的数据')
      }
      await this.$nextTick()
      this.printConfig = {
        ...print.config,
        printTemplateId: row.printTemplateId,
        check: this.check,
        lookPage
      }
    },
    // 预览
    lookPrint (print, row) {
      this.setPrintConfig(print, row, true)
    },
    // 新增模板
    addPrint () {
      this.$router.push({
        name: 'printTemplateDetail',
        query: { routerPath: this.routerPath,form:this.$route.path }
      })
    },
    // 直接打印
    nowPrint () {
      const nowPrint = {
        ...this.item.print.config,
        check: this.check
      }
      this.$router.push({
        name: 'printTemplateDetail',
        query: { routerPath: this.routerPath, nowPrint }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table-button-popper {
  padding: 0;
  .table-button-popper-content {
    text-align: center;
    margin: 0;
    padding: 4px 0;
    .table-button-popper-item {
      cursor: pointer;
      height: 30px;
      line-height: 30px;
      &:hover {
        background-color: #e8f4ff;
        color: #46a6ff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.el-form-myde {
  background-color: #f8f8f9;
  padding: 8px 0;
  margin-bottom: 10px;
  min-height: 48px;
  clear: both;

  ::v-deep .el-form-item {
    &:last-child {
      margin-right: 0;
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 0;
  height: 28px;
}
.el-dropdown {
  //审核按钮
  &.audit {
    margin-left: 10px;
    margin-right: 5px;
    ::v-deep.el-button-group > .el-button {
      background-color: #3cbf1d !important;
      color: #fff;
    }
    ::v-deep.el-button-group > .el-button:hover {
      background-color: #60c947 !important;
    }
  }
  //启用按钮
  &.startUsing {
    margin-left: 5px;
    margin-right: 10px;
    ::v-deep.el-button-group > .el-button {
      background-color: #ff8a00 !important;
      color: #fff;
    }
    ::v-deep.el-button-group > .el-button:hover {
      background-color: #f9a13a !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.table-page-print-view {
  padding: 3px 0px;
  background-color: #ffffff;
  position: relative;
  .print-view-sorcll {
    height: 160px;
    width: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    .print-view-list {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333333;
      &:hover {
        background-color: #f8f8f9;
      }
      /* 垂直滚动条样式 */
      /* 宽度 */
      &::-webkit-scrollbar {
        width: 8px;
      }
      /* 背景色 */
      &::-webkit-scrollbar-track {
        background-color: #ffffff;
      }
      /* 滑块颜色 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.5);
      }
      .print-view-list-title {
        width: 100%;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
      }
      .print-view-list-botton {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
      }
    }
  }
  .print-view-loading {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #858585;
  }
}
</style>
