<template>
  <div v-if="model" class="tableSearch">
    <div
      v-for="(searchItem, idx) in searchGroup ? '11' : '1'"
      class="tableSearch-view"
      :key="idx"
    >
      <el-form
        :inline="true"
        size="mini"
        v-show="idx == 0 ? true : seniorSearchIcon === 'seniorOpen'"
        :label-width="`${labelWidth}px`"
      >
        <template v-for="(item, i) in model">
          <el-form-item
            :key="item.type + i"
            :label="item.label"
            v-if="
              (idx === 0 ? !item.seniorSearch : item.seniorSearch) &&
              (item.hide ? item.hide(model) : true) &&
              (item.type === 'button' ? item.isLeft : true)
            "
            :label-width="`${item.labelWidth || labelWidth}px`"
          >
            <template v-if="item.from !== 'seniorSearch'">
              <!-- 自定义输入选择器 -->
              <SelectAllFilter
                v-if="item.type === 'filters'"
                v-model="item.model"
                :filters="item.filters"
                @selectChange="(val) => change(item, val)"
              />
              <!-- 带多选选择器 -->
              <SearchSelect
                v-if="item.type === 'all'"
                v-model="item.model"
                :disabled="item.disabled || false"
                :clearable="item.clearable || true"
                :filterable="item.filterable || false"
                :placeholder="item.tip"
                :data="item.data"
                :join="item.join"
                @selectChange="(val) => change(item, val)"
              />
              <!-- 本地数据选择器 -->
              <SelectLocal
                v-if="item.type === 'local'"
                v-model="item.model"
                :option="item.option"
                :filterable="item.option.filterable || false"
                :multiple="item.option.multiple || false"
                :clearable="item.option.clearable || false"
                :collapse-tags="item.option['collapse-tags']"
                :placeholder="item.placeholder || `选择${item.label}`"
                @selectChange="(val) => change(item, val)"
              />
              <!-- 远程数据选择器 -->
              <SelectRemote
                v-if="item.type === 'remote'"
                v-model="item.model"
                :option="item.option"
                :filterable="item.option.filterable || false"
                :multiple="item.option.multiple || false"
                :clearable="item.option.clearable || false"
                :collapse-tags="item.option['collapse-tags']"
                :placeholder="item.placeholder || `选择${item.label}`"
                @selectChange="(val) => change(item, val)"
              />
              <!-- 时间段选择器 -->
              <!--  :picker-options="{
                  disabledDate: (time) => disabledDate(time),
                  onPick: onPick,
                }"
                @focus="onPick" -->
              <el-date-picker
                class="date-picker"
                v-else-if="
                  [
                    'year',
                    'month',
                    'daterange',
                    'datetimerange',
                    'datetime',
                    'date',
                    'monthrange',
                  ].includes(item.type)
                "
                :placeholder="texts[item.type]"
                unlink-panels
                v-model="item.model"
                :disabled="item.option ? item.option.disabled : false"
                :range-separator="item.option ? item.option.rangeSeparato : '-'"
                :start-placeholder="
                  item.option ? item.option.startPlaceholder : '开始日期'
                "
                :end-placeholder="item.option ? item.option.endPlaceholder : '结束日期'"
                :default-time="
                  item.option ? item.option.defaultTime : defaultTime[item.type]
                "
                :type="item.type"
                :format="forMats[item.type]"
                :value-format="forMats[item.type]"
                :clearable="item.clearable || false"
                @selectChange="(val) => change(item, val)"
                :picker-options="item.disabledFuture ? pickerOptions : {}"
                popper-class="el-date_form"
                @change="timeChange(item)"
              />
              <timeRange
                :format="item.format"
                v-else-if="item.type === 'timeRange'"
                v-model="model[item.keyIndex].model"
                :defaultValue="item.defaultValue"
                :defaultChange="item.defaultChange"
                :data="item.data"
              />
              <MDrange v-else-if="item.type === 'MDrange'" v-model="item.model" />
              <!-- 标题文字 -->
              <div v-else-if="item.type === 'title'" style="font-size: 24px">
                {{ item.tip }}
              </div>
              <!-- 输入框 -->
              <el-input
                v-else-if="item.type === 'input'"
                v-model="item.model"
                :placeholder="item.tip || `输入${item.label}`"
                :disabled="item.disabled"
                :clearable="item.clearable"
                @keyup.native.enter="click('enterSearch')"
              />
              <!-- 数字 输入框 -->
              <el-input-number
                v-else-if="item.type === 'number'"
                :controls="item.controls || false"
                :style="`width: ${
                  item.style ? item.style.width : 140
                }px; text-align: left;`"
                v-model="item.model"
                :min="item.min || -Infinity"
                :max="item.max"
                :precision="item.precision || 0"
              />
              <!-- 去掉前后空格的 输入框 -->
              <el-input
                v-else-if="item.type === 'input.trim'"
                v-model.trim="item.model"
                :placeholder="item.tip || `输入${item.label}`"
                clearable
                @change="change(item, val)"
                @keyup.native.enter="click('enterSearch')"
              />
              <inputNumber v-else-if="item.type === 'numberRange'" v-model="item.model" />
              <!-- 单选 radio -->
              <el-radio-group
                v-model="item.model"
                v-else-if="item.type === 'radio'"
                @change="(val) => change(item, val)"
              >
                <el-radio
                  :label="radio.value"
                  v-for="radio in item.option.data"
                  :key="radio.label"
                  >{{ radio.label }}</el-radio
                >
              </el-radio-group>
              <el-checkbox v-model="item.model" v-else-if="item.type === 'checkbox'">{{item.option.label}}</el-checkbox>
              <span class="fontColorF00" v-else-if="item.type === 'textTip'">{{
                item.tip
              }}</span>
              <el-button
                v-else-if="item.type === 'button'"
                :disabled="
                  typeof item.disabled === 'function' ? item.disabled() : item.disabled
                "
                :type="
                  item.click === 'seniorSearch'
                    ? seniorSearchIcon === 'seniorOpen'
                      ? item.btnType
                      : undefined
                    : item.btnType
                "
                :plain="item.plain"
                :icon="
                  item.hideIcon
                    ? false
                    : icons[
                        item.click === 'seniorSearch'
                          ? seniorSearchIcon
                          : item.click !== 'search'
                          ? item.click
                          : false
                      ]
                "
                :roun="item.round"
                @click="click(item.click)"
                >{{ item.tip }}
              </el-button>
              <div
                v-else-if="item.type === 'equipartitionInterval'"
                style="display: inline-block"
              >
                <el-input
                  size="mini"
                  v-model="item.model.begin"
                  style="width: 100px"
                  maxlength="6"
                />
                <span style="margin-left: 10px">{{ item.unitName || "元" }}</span>
                <span style="margin: 0 10px">——</span>
                <el-input
                  size="mini"
                  v-model="item.model.end"
                  style="width: 100px"
                  maxlength="6"
                />
                <span style="margin: 10px">{{ item.unitName || "元" }}</span>
                <span style="margin-right: 10px">分</span>
                <el-input
                  size="mini"
                  v-model="item.model.number"
                  style="width: 100px"
                  maxlength="2"
                  @change="changeNumModel(item)"
                />
                <span style="margin: 10px">段</span>
                <span class="annotateGrey">最多可分10段</span>
              </div>
              <div
                v-else-if="item.type === 'optionalInterval'"
                style="display: inline-block"
              >
                <span
                  v-for="(itemInterval, indexInterval) in item.model"
                  :key="indexInterval"
                  class="interval x-bc"
                >
                  <span>{{ itemInterval.beginMoney + "-" + itemInterval.endMoney }}</span>
                  <i
                    class="cursorP el-icon-close"
                    @click="item.model.splice(indexInterval, 1)"
                  ></i>
                </span>
                <el-input
                  size="mini"
                  v-model="item.otherModel.beginMoney"
                  style="width: 100px"
                  maxlength="6"
                />
                <span style="margin-left: 10px">{{ item.unitName || "元" }}</span>
                <span style="margin: 0 10px">——</span>
                <el-input
                  size="mini"
                  v-model="item.otherModel.endMoney"
                  style="width: 100px"
                  maxlength="6"
                />
                <span style="margin: 10px">{{ item.unitName || "元" }}</span>
                <el-button type="primary" @click="addInterval(item)">添加</el-button>
                <span style="margin-left: 10px" class="annotateGrey">最多可分10段</span>
              </div>
            </template>
          </el-form-item>
        </template>
        <!-- 控制显示列 -->
        <el-form-item v-if="showColumn && show" label="展示项">
          <SearchSelect
            v-model="columnValue"
            placeholder="选择展示项"
            :data="columns"
            @selectChange="(val) => change(val, 'changeColumns')"
          />
        </el-form-item>
      </el-form>
      <div class="buttons" v-if="idx === 0">
        <el-form :inline="true" size="mini" class="my-form">
          <el-form-item v-for="(item, i) in buttons" :key="i">
            <!-- 按钮 undefined -->
            <el-button
              :disabled="
                typeof item.disabled === 'function' ? item.disabled() : item.disabled
              "
              :type="
                item.click === 'seniorSearch'
                  ? seniorSearchIcon === 'seniorOpen'
                    ? item.btnType
                    : undefined
                  : item.btnType
              "
              :plain="item.plain"
              :icon="
                item.hideIcon
                  ? false
                  : icons[
                      item.click === 'seniorSearch'
                        ? seniorSearchIcon
                        : item.click !== 'search'
                        ? item.click
                        : false
                    ]
              "
              :roun="item.round"
              @click="click(item.click)"
              >{{ item.tip }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import SelectAllFilter from "@/components/tablePage/select/select-all-filter/index.vue";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import SearchSelect from "@/components/tablePage/select/select-search/index.vue";
import inputNumber from "@/components/tablePage/input-number/index.vue";
import timeRange from "@/components/tablePage/time-range/index.vue";
import MDrange from "@/components/tablePage/MDrange/index.vue";
import { deepCopy } from "@/utils";
/**
 * 存储数据
 * @param {string} key - 存储数据的 key
 * @param {object} data - 要存储的数据
 * @param {boolean} isSession - 是否为 sessionStorage，默认为 localStorage
 * @param {number} expires - 过期时间，单位毫秒，为 0 时表示不过期，默认为 0
 */
function setStorage(key, data, { isSession = false, expires = 0 } = {}) {
  window[sessionFn(isSession)].setItem(
    getKey(key),
    JSON.stringify({
      data,
      [TIMESTAMP_KEY]: Date.now(),
      [EXPIRES_KEY]: expires,
    })
  );
}

/**
 * 删除指定 key 的数据
 * @param {string} key  - 删除数据的 key
 * @param {boolean} isSession - 是否为 sessionStorage
 */
function removeStorage(key, { isSession = false } = {}) {
  window[sessionFn(isSession)].removeItem(getKey(key));
}

/**
 * 获取数据
 * @param {string} key - 获取数据的 key
 * @param {boolean} isSession - 是否为 sessionStorage
 */
export function getStorage(key, { isSession = false } = {}) {
  try {
    const storageData = JSON.parse(window[sessionFn(isSession)].getItem(getKey(key)));
    if (!storageData) {
      return null;
    }
    const now = Date.now();
    // 如果该数据已过期，则删除
    if (
      storageData[EXPIRES_KEY] !== 0 &&
      now - storageData[TIMESTAMP_KEY] > storageData[EXPIRES_KEY]
    ) {
      removeStorage(key, { isSession });
      return null;
    }
    return storageData.data;
  } catch (e) {
    console.log("getStorage catch - ", e, getKey(key));
    return null;
  }
}
export default {
  name: "TabelPageTableSearch",
  components: {
    SelectAllFilter,
    SearchSelect,
    SelectLocal,
    SelectRemote,
    inputNumber,
    MDrange,
    timeRange,
  },
  model: { prop: "model", event: "Device" },
  props: {
    labelWidth: {
      type: [Number, String],
      default: 70,
    },
    model: Array,
    button: Array,
    column: Array,
    seniorSearch: Array,
    showColumn: Boolean,
    columnMote: Function,
  },
  computed: {
    buttons() {
      return this.button?.length
        ? this.button
        : this.model.filter(
            (x) =>
              (x.hide ? x.hide(this.model) : true) &&
              (x.type === "button" ? !x.isLeft : false)
          );
    },
    columns() {
      return [
        { value: "v@404@^", label: "全部展示" },
        ...this.column.map((x) => ({ value: x.prop, label: x.label })),
      ];
    },
    searchGroup() {
      return Boolean(this.model.find((x) => x.seniorSearch));
    },
    pickerOptions() {
      return {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      };
    },
  },
  data() {
    return {
      texts: {
        date: "请选择日期",
        datetime: "请选择日期时间",
      },
      defaultTime: {
        datetime: "00:00:00",
        datetimerange: ["00:00:00", "23:59:59"],
        daterange: ["00:00:00", "23:59:59"], // 日期选择器
      },
      forMats: {
        year: "yyyy",
        monthrange: "yyyy-MM",
        month: "yyyy-MM",
        date: "yyyy-MM-dd",
        datetime: "yyyy-MM-dd HH:mm:ss",
        datetimerange: "yyyy-MM-dd HH:mm:ss",
        daterange: "yyyy-MM-dd", // 日期选择器
      },
      show: false,
      columnValue: [],
      seniorSearchIcon: "seniorClose",
      icons: {
        search: "el-icon-search",
        seniorOpen: "el-icon-caret-bottom",
        seniorClose: "el-icon-caret-top",
        reset: "el-icon-refresh",
      },
    };
  },
  methods: {
    //限制可分区间
    changeNumModel(item) {
      if (item.model.number > 10) {
        item.model.number = 10;
      }
    },
    //添加区间
    addInterval(item) {
      if (item?.model?.length < 10) {
        item.model.push({
          beginMoney: item.otherModel.beginMoney,
          endMoney: item.otherModel.endMoney,
        });
      }
    },
    // 初始化展示项
    async getshowColumns() {
      if (!this.show && this.showColumn && this.userInfo?.enName) {
        let data = [];
        if (!this.columnMote) {
          data = getStorage(`${this.userInfo.enName}-${this.$route.name}`) || []; // 获取存储的列
        } else {
          data = await this.columnMote(this.userInfo.enName);
        }
        data = data.filter((x) => this.column.some((y) => y.prop === x)); // 去掉无效的字段
        if (!data || data.length === 0) {
          this.column.forEach((x) => {
            if (x.defaultShow) {
              data.push(x.prop); // 找默认显示列
            }
          });
        }
        this.columnValue = data;
        this.$emit(
          "update:column",
          this.column.map((x) => ({ ...x, hide: !data.includes(x.prop) }))
        );
        this.show = true;
      }
    },
    click(click) {
      if (click === "seniorSearch") {
        // 高级搜索
        this.seniorSearchIcon =
          this.seniorSearchIcon === "seniorOpen" ? "seniorClose" : "seniorOpen";
        // TODO 根据 艺林需求修改成 开关高级搜索不做任何操作

        // const data = deepCopy(this.model);
        // data.forEach((x) => {
        //   let value = "";
        //   if (!["button", "title"].includes(x.type) && x.seniorSearch && !x.noReset) {
        //     if (this.seniorSearchIcon === "seniorOpen") {
        //       const defaultValue = Object.keys(x).find((y) => y === "defaultValue");
        //       const isArr = x.type === "all" || x.option?.multiple;
        //       value = defaultValue ? x.defaultValue : isArr ? [] : "";
        //     }
        //     if (x.keyIndex >= 0) {
        //       this.config.search[x.keyIndex].model = value;
        //     } else {
        //       x.model = value;
        //     }
        //   }
        // });
        // this.$emit("Device", data);
        this.$emit("seniorSearchChange");
        // this.$emit("handleEvent", 'seniorSearch', this.seniorSearchIcon === "seniorOpen");
      } else {
        this.$emit("handleEvent", click);
      }
    },
    timeChange(e) {
      this.$emit("handleEvent", e.type, e.model);
    },
    // 选择器 选择事件
    change(item, val) {
      const { option } = item;
      console.log(option, "option");
      if (Array.isArray(item.clear)) {
        clear.forEach((x) => {});
      }
      switch (option?.type || "") {
        case "changeColumns": // 选中数据 Array
          {
            let data = val;
            data = JSON.parse(JSON.stringify(val));
            if (data[0] === "v@404@^") {
              data.shift();
            }
            if (!this.columnMote && this.userInfo?.enName) {
              setStorage(`${this.userInfo.enName}-${this.$route.name}`, data);
            } else if (this.columnMote && this.userInfo.enName) {
              this.columnMote(this.userInfo.enName, data);
            }
            this.$emit(
              "update:column",
              this.column.map((x) => ({ ...x, hide: !data.includes(x.prop) }))
            );
            this.$emit("handleEvent", option.type, data);
          }
          break;
        case "clear": // 选中数据 Array
          {
            const data = deepCopy(this.model);
            data.forEach((x) => {
              if (
                !["button", "title"].includes(x.type) &&
                !x.noReset &&
                option.clear.includes(x.filter)
              ) {
                const defaultValue = Object.keys(x).find((y) => y === "defaultValue");
                const isArr = x.type === "all" || x.option?.multiple;
                const value = defaultValue ? x.defaultValue : isArr ? [] : "";
                x.model = value;
              }
            });
            this.$emit("Device", data);
          }
          break;
        default:
          if (typeof item.change === "function") {
            item.change(val);
          }
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tableSearch {
  .my-form {
    ::v-deep .el-form-item {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tableSearch-view {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
    .buttons {
      white-space: nowrap;
    }
  }
  .date-picker {
    width: auto !important;
    ::v-deep .el-range-input {
      max-width: 112px !important;
      width: 112px !important;
      // font-size: 14px !important;
    }
  }
}

.interval {
  width: 80px;
  background-color: #46a6ff;
  margin-right: 10px;
  padding: 0 10px;
  border-radius: 6px;
  cursor: default;
  color: #ffffff;
  display: inline-block;
}
/* 隐藏清空按钮 */
::v-dee .el-date-editor .el-input__suffix .el-input__clear {
  display: none !important;
}
</style>
