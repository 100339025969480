var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.config.form,
        "label-width": "96px",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrap",
          attrs: { id: "wrap" },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              isClearDetailsBtn: !_vm.disabled,
              isZeroClearingBtn: !_vm.disabled,
              isImportOrderBtn:
                _vm.config.buttons &&
                _vm.config.buttons.isImportOrderBtn &&
                _vm.showImportBill,
              disabled: _vm.disabled,
              showAudit: _vm.showAudit,
              isAddBtn: _vm.showAdd,
              id: "topOperatingButton",
            },
            on: {
              getClearDetails: _vm.getClearDetails,
              getZeroClearing: _vm.getZeroClearing,
              submitForm: _vm.submitForm,
              addBill: _vm.addBill,
              auditBill: _vm.auditBill,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
              getImportOrder: function ($event) {
                return _vm.handleEvent("importOrder")
              },
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                billStatus: _vm.config.form.billStatus,
                id: "basicInformation",
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-w marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: "",
                            placeholder: "单据编号",
                            size: "mini",
                          },
                          model: {
                            value: _vm.config.form.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.form, "billNo", $$v)
                            },
                            expression: "config.form.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            type: "date",
                            placeholder: "单据日期",
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            size: "mini",
                          },
                          model: {
                            value: _vm.config.form.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.form, "billDate", $$v)
                            },
                            expression: "config.form.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手工单据号", prop: "handBillNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "手工单据号",
                            size: "mini",
                          },
                          model: {
                            value: _vm.config.form.handBillNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.form, "handBillNo", $$v)
                            },
                            expression: "config.form.handBillNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产工厂", prop: "produceDeptId" } },
                      [
                        _c("SelectRemote", {
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                              option: {
                                option: {
                                  disabled: _vm.disabled,
                                  showItem: [
                                    {
                                      produceDeptId:
                                        _vm.config.form.produceDeptId,
                                      produceDeptName:
                                        _vm.config.form.produceDeptName,
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.config.form.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.form, "produceDeptId", $$v)
                            },
                            expression: "config.form.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.config.topQuery && _vm.config.topQuery.isProcess
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "生产工序", prop: "processId" } },
                          [
                            _c("SelectRemote", {
                              attrs: {
                                option: _vm.$select({
                                  key: "listProcess",
                                  option: {
                                    option: {
                                      disabled: _vm.disabled,
                                      showItem: [
                                        {
                                          processId: _vm.config.form.processId,
                                          processName:
                                            _vm.config.form.processName,
                                        },
                                      ],
                                    },
                                  },
                                }).option,
                              },
                              model: {
                                value: _vm.config.form.processId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.config.form, "processId", $$v)
                                },
                                expression: "config.form.processId",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputRemark",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 5 },
                            size: "mini",
                          },
                          model: {
                            value: _vm.config.form.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.form, "billRemark", $$v)
                            },
                            expression: "config.form.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "商品信息" } },
            [
              _vm.config.rightCardTitle
                ? _c("template", { slot: "rightCardTitle" }, [
                    _c(
                      "div",
                      { staticClass: "marR10 x-f" },
                      [
                        _vm.config.rightCardTitle.isStoreBtn
                          ? _c(
                              "el-button",
                              {
                                staticClass: "marL10",
                                attrs: {
                                  disabled:
                                    _vm.disabled ||
                                    !_vm.config.form.produceDeptId ||
                                    _vm.ids.length == 0,
                                  type: "primary",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getDistribution("isStoreBtn")
                                  },
                                },
                              },
                              [_vm._v("批量修改仓库")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "vxe-table",
                      {
                        ref: "multipleTable",
                        attrs: {
                          border: "",
                          "show-footer": "",
                          "show-overflow": "",
                          "show-header-overflow": "",
                          data: _vm.config.form.details,
                          loading: _vm.loadingTable,
                          "max-height": "580px",
                          height: "580px",
                          "checkbox-config": {
                            trigger: "row",
                            checkMethod: function () {
                              return !_vm.disabled
                            },
                          },
                          "row-config": { isHover: true, height: 50 },
                          "sort-config": { trigger: "cell" },
                          "scroll-y": { enabled: true },
                          "footer-method": _vm.footerMethod,
                          size: "small",
                        },
                        on: {
                          "checkbox-change": _vm.handleSelectionChange,
                          "checkbox-all": _vm.handleSelectionChange,
                        },
                      },
                      [
                        _vm.config.mutiSelect
                          ? _c("vxe-column", {
                              attrs: {
                                fixed: "left",
                                type: "checkbox",
                                width: "60",
                                align: "center",
                              },
                            })
                          : _vm._e(),
                        _c("vxe-column", {
                          attrs: {
                            align: "center",
                            title: _vm.tableCellLabel,
                            width: "80",
                            fixed: "left",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.cellMouseEnter(scope.row)
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.cellMouseLeave(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.hoverRow,
                                            expression: "scope.row.hoverRow",
                                          },
                                        ],
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.row(
                                              "push",
                                              scope.rowIndex
                                            )
                                          },
                                        },
                                      }),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.hoverRow,
                                            expression: "scope.row.hoverRow",
                                          },
                                        ],
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.row(
                                              "del",
                                              scope.rowIndex
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !scope.row.hoverRow,
                                              expression: "!scope.row.hoverRow",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.rowIndex + 1) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._l(_vm.config.columns, function (item, i) {
                          return [
                            typeof item.getColumns === "function" &&
                            _vm.config.form.details &&
                            _vm.config.form.details.length
                              ? _vm._l(
                                  item.getColumns(_vm.config.list),
                                  function (item, index) {
                                    return _c("TableColumns", {
                                      key: item.prop + "." + i + "." + index,
                                      attrs: {
                                        column: item,
                                        disabled: _vm.disabled,
                                      },
                                      on: { handleEvent: _vm.handleEvent },
                                    })
                                  }
                                )
                              : item.type === "slot"
                              ? _vm._t(item.prop, null, { slot: item.prop })
                              : _c(
                                  "TableColumns",
                                  {
                                    key: item.prop + "." + i,
                                    attrs: {
                                      column: item,
                                      disabled: _vm.disabled,
                                    },
                                    on: { handleEvent: _vm.handleEvent },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "slot-" + item.prop,
                                          fn: function (scope) {
                                            return [
                                              _vm._t(
                                                item.prop,
                                                null,
                                                null,
                                                scope
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    item.type === "slots"
                                      ? _vm._t(item.prop, null, {
                                          slot: item.prop,
                                        })
                                      : _vm._e(),
                                    item.type === "multiple"
                                      ? _vm._t(item.prop, null, {
                                          slot: item.prop,
                                        })
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEventDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }