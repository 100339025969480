<template>
  <el-table :class="classTitle? 'dragTable' : ''" ref="mutipleTable" v-loading="loading"
    header-cell-class-name="table-header" v-bind="$attrs" :data="list" :summary-method="getSummaries"
    :show-summary="showSummary" tooltip-effect="dark myTooltips" @selection-change="selectionChange" @select="select"
    @select-all="selectAll" @row-click="rowClick" @header-dragend="headerDragend" :span-method="headerCellStyle"
    @sort-change="sortChange" @expand-change="expandChange" @cell-mouse-enter="mouseEventEnter"
    @cell-mouse-leave="mouseEventLeave">
    <!--region 选择框 -->
    <el-table-column v-if="mutiSelect" :reserve-selection="reserveSelection" type="selection" align="center" width="55"
      :selectable="selectable" fixed />
    <!--endregion-->
    <!--region 数据列-->
    <slot name="table-column" />
    <!--endregion-->
    <!--region 按钮操作组-->
    <el-table-column v-if="operatesShow" label="操作" :width="operateWidth || operates.width" fixed="right" align="center"
      :show-overflow-tooltip="false">
      <template slot-scope="scope">
        <div v-if="operates.list && operates.list.length > 0" class="operate-group">
          <template v-for="(btn, key) in operates.list">
            <div v-if="!btn.hide" :key="key" class="item">
              <el-button :circle="btn.circle" :type="btn.type || 'text'" size="mini" :icon="btn.icon"
                :disabled="btn.disabled" :plain="btn.plain"
                @click.native.prevent.stop="click(btn, scope)">{{ btn.text }}</el-button>
            </div>
          </template>
        </div>
        <!--region 插槽模式-->
        <slot v-else name="table-operate" v-bind="scope" />
        <!--endregion-->
      </template>
    </el-table-column>
    <!--endregion-->
  </el-table>
</template>
<script>
import Sortable from 'sortablejs'
import { fcount, isObject, isNumber, deepCopy } from "@/utils";
/** 组件使用说明
 * @param {Array} list 列表数据
 * @param {Object} operates 操作按钮，不传值默认使用插槽，外显用组件写
 * @param {Boolean} operateWidth 操作列的宽度
 * @param {Boolean} loading 是否添加表格loading加载动画
 * @param {Boolean} mutiSelect 是否显示多选框
 * @param {Boolean} reserveSelection 仅对 type=selection 的列有效，类型为 Boolean，为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key）
 * @param {Function} headerCellStyle 合并列及行的计算
 */
export default {
  // 组件
  name: "ComponentTable",
  props: {
    // 是否可以拖拽
    classTitle: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    // 数据列表
    list: {
      type: Array,
      default: () => [],
    },
    // 操作按钮组 === label: 文本，type :类型（primary / success / warning / danger / info / text），show：是否显示，icon：按钮图标，plain：是否朴素按钮，disabled：是否禁用，method：回调方法
    operates: {
      type: [Object, Boolean],
      default: false, // 是否启用操作按钮 默认启用 传了false 不启用操作
    },
    // 操作列的宽度
    operateWidth: {
      type: String,
      default: "",
    },
    // 是否添加表格loading加载动画
    loading: {
      type: Boolean,
      default: false,
    },
    // 是否显示多选框
    mutiSelect: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: [Boolean, Array],
      default: false,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    summaryDateils: {
      type: Object,
      default: null,
    },
    printSummaryData: {
      type: [Boolean, Array],
      default: false,
    },
    // 仅对 type=selection 的列有效，类型为 Boolean，为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key）
    reserveSelection: {
      type: Boolean,
      default: false,
    },
    headerCellStyle: {
      type: Function,
      default: () => { },
    },
    // 是否需要父子联动
    linkage: {
      type: Boolean,
      default: false,
    },
    // 是否开启虚拟滚动
    virtualScroll: {
      type: Boolean,
      default: false,
    },
  },
  // 数据
  data() {
    return {
      activeRows:[]
    };
  },
  computed: {
    // 是否 显示按钮操作组
    operatesShow() {
      return (
        typeof this.operates === "object" && this.operates?.list?.length > 0
      );
    },
  },
  watch: {
    list(val) {
    },
  },
  mounted() {
   if (this.classTitle) {
        this.$nextTick(()=>{
        this.rowDrop();
        })
      }
  },
  methods: {
    rowDrop() {
      // const table = document.querySelector('.dragTable .el-table__body-wrapper  table tbody')
     const tbody = document.querySelector('.dragTable .el-table__body-wrapper  table tbody')
      Sortable.create(tbody, {
        animation: 200, // 定义排序动画的时间
        forceFallback: true, // boolean 如果设置为true时，将不使用原生的html5的拖放，可以修改一些拖放中元素的样式等；
        onMove: ({ dragged, related }) => {
          const oldRow = this.activeRows[dragged.rowIndex] // 移动的那个元素
          const newRow = this.activeRows[related.rowIndex] // 新的元素
          console.log('oldRow', oldRow.parentId,newRow.parentId);
          if (oldRow.parentId !== newRow.parentId) { // 移动的元素与新元素父级id不相同
            return false // 不允许跨级拖动
          }
        },
        onStart: () => { // 开始拖拽前把树形结构数据扁平化
          this.activeRows = this.treeToTile(this.list) // 把树形的结构转为列表再进行拖拽
        },
        onEnd: e => {
          const oldRow = this.activeRows[e.oldIndex] // 移动的那个元素
          const newRow = this.activeRows[e.newIndex] // 新的元素
          if (e.oldIndex === e.newIndex || oldRow.parentId !== newRow.parentId) return
          const index = this.activeRows.indexOf(oldRow)
          if (index < 0) return
          const changeIndex = e.newIndex - e.oldIndex
          this.activeRows.splice(index, 1)
          this.activeRows.splice(index + changeIndex, 0, oldRow)
          const parentId = newRow.parentId
          const currentRows = this.activeRows
            .filter(c => c.parentId === parentId)
            ?.map((item, index) => {
              return {
                categoryName: item.categoryName, // 名称
                categoryId: item.categoryId, // 当前行的唯一标识
                sortNo: index + 1,
                parentId: item.parentId
              }
            })
          console.log('currentRows1',this.activeRows);
          console.log('currentRows2',e.newIndex);
          let currentRowsNewCategoryId = this.activeRows[e.newIndex].categoryId
          let currentRowsNewCategory = currentRows.find(v=>v.categoryId === currentRowsNewCategoryId)
          // 请求接口排序，根据后端要求填写参数
          // this.handleGetApi(currentRows) // 请求后端排序接口
          console.log('currentRows',currentRowsNewCategory);
          this.$emit("handleEvent", "dragTableEnd", currentRowsNewCategory);
        }
      })
      // const that = this
      // Sortable.create(table, {
      //   group: 'categoryName',  // or any other group name
      //   animation: 150,
      //   fallbackOnBody: true,
      //   swapThreshold: 0.65,
      //   // animation: 150,
      //   // ghostClass: 'blue-background-class',
      //   // handle: '.drag_btn', // 如果需要点击某个图标拖拽的话需要吧那个图标的class写在这里
      //   // animation: 1000,
      //   // //取消选中事件
      //   onUnchoose(item) {
      //     // console.log(item, 'item, item onUnchoose');
      //   },
      //   //点击选中元素事件
      //   onChoose(item) {
      //     that.$emit("handleEvent", "handleChoose", item);
      //   },
      //   // 始拖拽事件
      //   onEnd(evt) {
      //     const itemId = evt.item.getAttribute('data-categoryId')
      //     console.log('onChoose', itemId);
      //     console.log('onChoose2', evt);
      //     that.$emit("handleEvent", "dragTableEnd", evt);
      //   },
      //   onMove(evt) {
      //     that.$emit("handleEvent", "handleMove", evt);
      //   },
      // });
    },
     treeToTile (treeData, childKey = 'children') {
      const arr = []
      const expanded = data => {
        if (data && data.length > 0) {
          data.filter(d => d).forEach(e => {
            arr.push(e)
            expanded(e[childKey] || [])
          })
        }
      }
      expanded(treeData)
      return arr
    },
    mouseEventEnter(row) {
      let tableIndex = this.list.indexOf(row);
      this.$emit("tableMouseEvent", "enter", row);
      this.$emit("handleEvent", "tableIndex", tableIndex);
      this.$emit("cell-mouse-enter", row);
    },
    mouseEventLeave(row) {
      this.$emit("tableMouseEvent", "leave", row);
      this.$emit("cell-mouse-leave", row);
    },
    sortChange({ column, prop, order }) {
      this.$emit("handleEvent", "sortChange", { prop, order });
    },
    // 当选择项发生变化时会触发该事件
    selectionChange(...selection) {
      this.$emit("selection-change", ...selection);
    },
    click(btn, scope) {
      // space
      if (btn.method) {
        btn.method(scope.row, scope);
      } else {
        this.$emit("handleEvent", btn.click, row, scope);
      }
    },
    rowClick(...selection) {
      this.$emit("rowClick", ...selection);
    },
    selectAll(selection, row) {
      this.$emit("select-all", selection);
      if (!this.linkage) return;
      const find = this.list.find((item) => {
        const ids = selection.map((item) => item.productId);
        return ids.includes(item.productId);
      });
      this.list.forEach((item) => {
        if (item.children) {
          this.selectChildren(item.children, !!find);
        }
      });
    },
    headerDragend(...e) {
      this.$nextTick(() => {
        try {
          this.$refs?.mutipleTable?.doLayout();
        } catch (error) { }
      });
      this.$emit("header-dragend", ...e);
    },
    expandChange(...e) {
      this.$nextTick(() => {
        try {
          this.$refs?.mutipleTable?.doLayout();
        } catch (error) { }
      });
      this.$emit("expand-change", ...e);
    },
    select(selection, row) {
      this.$emit("select", selection, row);
      if (!this.linkage) return;
      const find = selection.find((item) => item.productId === row.productId);
      row.children && this.selectChildren(row.children, !!find);
    },
    // 子级选中
    selectChildren(row, type) {
      row.map((i) => {
        this.toggleSection(i, type);
        if (i.children) {
          this.selectChildren(i.children, type);
        }
      });
    },
    toggleSection(row, select) {
      row && this.$refs.mutipleTable.toggleRowSelection(row, select);
    },
    getSummaries({ columns, data } = {}) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const sumItem = this.summary.find((x) =>
          isObject(x) ? x.key === column.property : x === column.property
        );
        if (sumItem) {
          if (this.summaryDateils) {
            const arr = column.property.split(".");
            if (arr?.length === 4) {
              const [key, id, idKey, prop] = arr;
              const keyArr = key.split("_");
              let detailData = null;
              keyArr.forEach((x) => {
                if (detailData) {
                  detailData = detailData[x];
                } else {
                  detailData = this.summaryDateils[x];
                }
              });
              sums[index] =
                detailData?.find?.((x) => Number(x[idKey]) === Number(id))?.[
                prop
                ] || "0";
            } else {
              sums[index] = this.summaryDateils[column.property] || "";
            }
          } else {
            const arr = column.property.split(".");
            const allData = this.printSummaryData ? this.printSummaryData : data;
            let values = allData.map((x) => Number(x[column.property] || 0));
            if (arr?.length === 4) {
              const [p, i, key, s] = arr;
              values = allData.map(
                (x) => x[p]?.find?.((x) => String(x[key]) === i)?.[s] || 0
              );
            }
            sums[index] = `${values.reduce(
              (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
              0
            )}${isObject(sumItem) ? ` ${sumItem.unit}` : ""}`;
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
  }
};
</script>
<style lang="scss" scoped>
.operate-group {
  display: flex;
  justify-content: space-around;
}

::v-deep .table-header {
  background-color: #f7f7f7;
}

::v-deep .el-table__body-wrapper {
  background-color: #ffffff;
}
</style>
<style>
.myTooltips {
  max-width: 700px;
}
</style>
